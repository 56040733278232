type DrawBboxProps = {
  ctx: CanvasRenderingContext2D | null
  x: number
  y: number
  height: number
  width: number
  filled?: boolean
  rounded?: boolean
  radii?: number
}
export const drawBbox = ({
  ctx,
  x,
  y,
  height,
  width,
  filled,
  rounded,
  radii,
}: DrawBboxProps) => {
  if (ctx === null) return

  ctx?.beginPath()
  if (rounded) {
    ctx?.roundRect(x, y, width, height, radii || height / 2)
    if (filled) {
      ctx?.fill()
    } else {
      ctx?.stroke()
    }
  } else if (filled) {
    ctx?.fillRect(x, y, width, height)
  } else {
    ctx?.strokeRect(x, y, width, height)
  }
}
