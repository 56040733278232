import * as poseDetection from '@tensorflow-models/pose-detection'
import { drawPoint } from './drawPoint'
import { drawLine } from './drawLine'
import { forbiddenPointNames } from './constants'

type DrawSkeletonProps = {
  ctx: CanvasRenderingContext2D | null
  keypoints: poseDetection.Keypoint[]
  scaleX: number
  scaleY: number
}

export const drawSkeleton = async ({
  ctx,
  keypoints,
  scaleX,
  scaleY,
}: DrawSkeletonProps) => {
  const points = poseDetection.util.getAdjacentPairs(
    poseDetection.SupportedModels.MoveNet
  )

  keypoints.forEach((point) => {
    if (forbiddenPointNames.some((name) => name === point.name)) return
    drawPoint({ ctx, point, scaleX, scaleY })
  })

  points.forEach((pair) => {
    if (pair[0] < 5 && pair[1] < 5) {
      return
    }

    drawLine({
      ctx,
      startPoint: keypoints[pair[0]],
      endPoint: keypoints[pair[1]],
      scaleX,
      scaleY,
    })
  })
}
