import * as faceapi from '@vladmandic/face-api'

import { capitalizeString } from '../stringHelpers'
import { barOffsetX } from './constants'
import { drawBbox } from './drawBbox'
import { drawBinarLogoBig } from './drawBinarLogoBig'
import { drawMoodItem } from './drawMoodItem'

type DrawMoodBoxProps = {
  ctx: CanvasRenderingContext2D
  expressions: faceapi.FaceExpressions
  height: number
  properWidth: number
  scaleX: number
  scaleY: number
  width: number
  x: number
  y: number
}

export const drawMoodBox = ({
  ctx,
  expressions,
  height,
  properWidth,
  scaleX,
  scaleY,
  width,
  x,
  y,
}: DrawMoodBoxProps) => {
  ctx.fillStyle = '#ffffffcc'

  drawBbox({
    ctx,
    filled: true,
    height: 390,
    radii: 8,
    rounded: true,
    width: 250,
    x: (x + width) * scaleX + 10,
    y: y * scaleY,
  })

  // draw full binar logo
  drawBinarLogoBig({
    ctx,
    height,
    properWidth,
    scaleX,
    scaleY,
    width,
    x,
    y,
  })

  ctx.fillStyle = '#2B185C'
  ctx.strokeStyle = '#2B185C'

  ctx.font = '16px Montserrat'
  ctx.textAlign = 'start'

  ctx.fillText(
    'Main mood components:',
    (x + width) * scaleX + barOffsetX,
    y * scaleY + 90
  )

  // draw mood items (bars) in mood box
  Object.entries(expressions).forEach((el, index) => {
    drawMoodItem({
      ctx,
      index,
      name: capitalizeString(el[0]),
      scaleX,
      scaleY,
      value: el[1],
      width,
      x,
      y,
    })
  })
}
