import { barHeight, barOffsetX, barOffsetY, barWidth } from './constants'
import { drawBbox } from './drawBbox'

type DrawMoodItemProps = {
  ctx: CanvasRenderingContext2D | null
  name: string
  value: number
  index: number
  x: number
  y: number
  width: number
  scaleX: number
  scaleY: number
}

export const drawMoodItem = ({
  ctx,
  name,
  value,
  index,
  scaleX,
  scaleY,
  x,
  width,
  y,
}: DrawMoodItemProps) => {
  if (ctx === null) return
  ctx.lineWidth = 1

  ctx.fillStyle = '#2B185C'
  ctx.strokeStyle = '#2B185C'

  drawBbox({
    ctx,
    x: (x + width) * scaleX + barOffsetX,
    y: y * scaleY + index * 40 + barOffsetY,
    width: barWidth,
    height: barHeight,
  })

  drawBbox({
    filled: true,
    ctx,
    x: (x + width) * scaleX + barOffsetX,
    y: y * scaleY + index * 40 + barOffsetY,
    width: barWidth * value,
    height: barHeight,
  })
  ctx.font = '12px Montserrat'
  ctx.textAlign = 'start'
  ctx.fillText(
    name,
    (x + width) * scaleX + barOffsetX,
    (y + barHeight / 2) * scaleY + 40 * index + 20 + barOffsetY
  )
  ctx.textAlign = 'end'
  ctx.fillText(
    ((value || 1) * 100).toFixed(0) + '%',
    (x + width) * scaleX + barOffsetX + barWidth,
    (y + barHeight / 2) * scaleY + 40 * index + 20 + barOffsetY
  )
}
