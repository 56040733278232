import * as poseDetection from '@tensorflow-models/pose-detection'
import { createRef, MutableRefObject } from 'react'

import createGenericContext from '../utils/createGenericContext'

export type TensorFlowContextType = {
  isTfReady: boolean
  poseDetector: poseDetection.PoseDetector | null
}

export const [useTensorFlowContext, TensorFlowContextProvider] =
  createGenericContext<TensorFlowContextType>('TensorFlowContext')

export const tensorFlowContextRef: MutableRefObject<TensorFlowContextType | null> =
  createRef()
