import '@tensorflow/tfjs-backend-webgl'
import * as tf from '@tensorflow/tfjs'
import * as faceapi from '@vladmandic/face-api'
import { useCallback, useEffect, useRef, useState } from 'react'
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition'
import Webcam from 'react-webcam'

import './App.css'
import { useTensorFlowContext } from './context'
import {
  clearCanvas,
  drawFaceBbox,
  drawMoodBox,
  drawMostProbableExpressionBox,
  drawSkeleton,
} from './utils'

const canvasWidth = window.innerWidth
const canvasHeight = window.innerHeight

const cameraResolutionWidth = 1280
const cameraResolutionHeight = 720

const scoreThreshold = 0.5

const scaleX = canvasWidth / cameraResolutionWidth
const scaleY = canvasHeight / cameraResolutionHeight

const detector = new faceapi.TinyFaceDetectorOptions({ scoreThreshold })

tf.setBackend('webgl')

function App() {
  const webcamRef = useRef<Webcam | null>(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  const [isRecording, setIsRecording] = useState(false)
  const { transcript, resetTranscript } = useSpeechRecognition()

  const { poseDetector } = useTensorFlowContext()

  const [prevPosition, setPrevPosition] = useState<
    {
      x: number
      y: number
    }[]
  >([])

  const detectPose = useCallback(async () => {
    if (
      webcamRef.current !== null &&
      webcamRef.current.video?.readyState === 4
    ) {
      // get video properties
      const video = webcamRef.current.video
      const videoWidth = webcamRef.current.video.videoWidth
      const videoHeight = webcamRef.current.video.videoHeight

      // set video width

      webcamRef.current.video.width = videoWidth
      webcamRef.current.video.height = videoHeight
      let faces: faceapi.WithFaceExpressions<{
        detection: faceapi.FaceDetection
      }>[] = []
      try {
        faces = await faceapi
          .detectAllFaces(video, detector)
          .withFaceExpressions()
      } catch (e) {
        console.log('ERROR when detecting faces', e)
      }
      const poses = await poseDetector?.estimatePoses(video)

      return { poses, faces }
    }
  }, [poseDetector])

  const checkIfShouldReDraw = useCallback(
    (
      faces:
        | faceapi.WithFaceExpressions<{
            detection: faceapi.FaceDetection
          }>[]
        | undefined,
      prevFacePositions: { x: number; y: number }[]
    ) => {
      const shouldReDraw = faces?.some(
        (
          {
            detection: {
              box: { x, y },
            },
          },
          index
        ) => {
          const prevX = prevFacePositions?.[index]?.x || 0
          const prevY = prevFacePositions?.[index]?.y || 0

          const reDraw = Math.abs(prevX - x) > 10 || Math.abs(prevY - y) > 10
          return reDraw
        }
      )
      if (shouldReDraw) {
        const newPrevPosition =
          faces?.map(
            ({
              detection: {
                box: { x, y },
              },
            }) => {
              return { x, y }
            }
          ) || []
        setPrevPosition(newPrevPosition)
      }
      return shouldReDraw
    },
    []
  )

  const detectAndDrawPoses = useCallback(async () => {
    const data = await detectPose()

    const poses = data?.poses
    const faces = data?.faces

    if (!canvasRef.current) {
      return
    }

    // TODO: handle redraw properly
    // const shouldReDraw = checkIfShouldReDraw(faces, prevPosition)

    const ctx = canvasRef.current?.getContext('2d')

    if (ctx === null) return

    clearCanvas({ ctx, canvasWidth, canvasHeight })

    if (poses && poses?.length) {
      poses?.forEach((pose) => {
        drawSkeleton({ ctx, keypoints: pose?.keypoints, scaleX, scaleY })
      })
    }
    // draw face bbox and box with most pop=bable expression above face bbox
    if (faces && faces?.length) {
      faces?.forEach(
        ({
          detection: {
            box: { x, y, height, width },
          },
          expressions,
        }) => {
          // draw bbox around face
          drawFaceBbox({ ctx, height, width, scaleX, scaleY, x, y })

          // get minimum 400 px width for most probable expression box
          const properWidth = width * scaleX > 400 ? width : 400 / scaleX

          // draw upper box with most probable expression
          drawMostProbableExpressionBox({
            ctx,
            expressions,
            properWidth,
            scaleX,
            scaleY,
            x,
            y,
          })

          // draw right 'box' with all moods
          drawMoodBox({
            ctx,
            expressions,
            height,
            properWidth,
            scaleX,
            scaleY,
            width,
            x,
            y,
          })
        }
      )
    }
  }, [detectPose])

  const startListening = useCallback(async () => {
    try {
      setIsRecording(true)
      await SpeechRecognition.startListening({
        continuous: true,
        language: 'en-GB',
      })
    } catch (e) {
      console.log(e)
    }
  }, [])

  const stopListening = useCallback(async () => {
    setIsRecording(false)
    await SpeechRecognition.stopListening()
    resetTranscript()
  }, [resetTranscript])

  useEffect(() => {
    const interval = setInterval(() => {
      detectAndDrawPoses()
    }, 25)
    return () => clearInterval(interval)
  }, [detectAndDrawPoses])

  return (
    <div className="App">
      <link rel="preconnect" href="https://fonts.googleapis.com"></link>
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin=""
      ></link>
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap"
        rel="stylesheet"
      ></link>
      <header className="App-header">
        <div style={{ display: 'flex' }}>
          <Webcam
            ref={webcamRef}
            style={{
              width: canvasWidth,
              height: canvasHeight,
            }}
            videoConstraints={{
              height: cameraResolutionHeight,
              width: cameraResolutionWidth,
            }}
          />
          <canvas
            className="canvas"
            ref={canvasRef}
            style={{
              width: canvasWidth,
              height: canvasHeight,
            }}
          />

          {/* HIDE VOICE RECOGNITION FEATURE */}
          {/* <div className="box">
            <span className="ellipsis">{transcript}</span>
          </div>

          <button
            onClick={startListening}
            disabled={isRecording}
            style={{ position: 'absolute', bottom: 10, left: 10, zIndex: 20 }}
          >
            START LISTENING
          </button>
          <button
            disabled={!isRecording}
            onClick={stopListening}
            style={{ position: 'absolute', bottom: 10, left: 160, zIndex: 20 }}
          >
            STOP LISTENING
          </button> */}
        </div>
      </header>
    </div>
  )
}

export default App
