import { drawBbox } from './drawBbox'

type DrawFaceBboxProps = {
  ctx: CanvasRenderingContext2D
  height: number
  scaleX: number
  scaleY: number
  width: number
  x: number
  y: number
}
export const drawFaceBbox = ({
  ctx,
  height,
  scaleX,
  scaleY,
  width,
  x,
  y,
}: DrawFaceBboxProps) => {
  ctx.strokeStyle = '#EB5757'
  ctx.lineWidth = 3
  drawBbox({
    ctx,
    x: x * scaleX,
    y: y * scaleY,
    height: height * scaleY,
    width: width * scaleX,
    rounded: true,
    radii: 8,
  })
}
