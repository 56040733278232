import * as poseDetection from '@tensorflow-models/pose-detection'
import { pointScoreThreshold } from './constants'

type DrawLineProps = {
  ctx: CanvasRenderingContext2D | null
  startPoint: poseDetection.Keypoint
  endPoint: poseDetection.Keypoint
  scaleX: number
  scaleY: number
}

export const drawLine = ({
  ctx,
  startPoint,
  endPoint,
  scaleX,
  scaleY,
}: DrawLineProps) => {
  if (ctx === null) return
  if (
    (startPoint?.score || 1) < pointScoreThreshold ||
    (endPoint?.score || 1) < pointScoreThreshold
  )
    return

  ctx.strokeStyle = '#EB5757'
  ctx.lineWidth = 3
  ctx.beginPath()
  ctx.moveTo(
    Math.floor(scaleX * startPoint.x),
    Math.floor(scaleY * startPoint.y)
  )
  ctx.lineTo(Math.floor(scaleX * endPoint.x), Math.floor(scaleY * endPoint.y))
  ctx.closePath()
  ctx.stroke()
}
