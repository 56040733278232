import * as faceapi from '@vladmandic/face-api'

import { getMostProbableExpression } from '../getMostProbableExpression'
import { capitalizeString } from '../stringHelpers'
import { drawBbox } from './drawBbox'
import { drawBinarLogoSmall } from './drawBinarLogoSmall'

const starsLeft = new Image()
starsLeft.src = 'stars_left.png'

const starsRight = new Image()
starsRight.src = 'stars_right.png'

type DrawMostProbableExpressionBox = {
  ctx: CanvasRenderingContext2D
  expressions: faceapi.FaceExpressions
  properWidth: number
  scaleX: number
  scaleY: number
  x: number
  y: number
}

export const drawMostProbableExpressionBox = ({
  ctx,
  expressions,
  properWidth,
  scaleX,
  scaleY,
  x,
  y,
}: DrawMostProbableExpressionBox) => {
  const { expression, probability } = getMostProbableExpression({
    expressions,
  })
  ctx.fillStyle = '#ffffffcc'

  drawBbox({
    ctx,
    filled: true,
    height: 100,
    radii: 8,
    rounded: true,
    width: properWidth * scaleX,
    x: x * scaleX,
    y: y * scaleY - 120,
  })

  // DRAW SMALL BINAR LOGO
  drawBinarLogoSmall({ ctx, properWidth, scaleX, scaleY, x, y })

  // show most probable expression above face bbox
  ctx.font = '12px Montserrat'
  ctx.fillStyle = '#2B185C'
  ctx.textAlign = 'center'
  ctx.fillText(
    'Hi! You looks great today!',
    (x + properWidth / 2) * scaleX,
    y * scaleY - 85
  )
  ctx.font = '24px Montserrat'
  ctx.fillText(
    'Your mood is: ' +
      capitalizeString(expression) +
      ' ' +
      (probability * 100).toFixed(0) +
      '%',
    (x + properWidth / 2) * scaleX,
    (y - 15) * scaleY - 20
  )

  // draw stars
  ctx.drawImage(starsLeft, x * scaleX - 20, y * scaleY - 120)
  ctx.drawImage(starsRight, (x + properWidth) * scaleX - 20, y * scaleY - 120)
}
