import { barOffsetX } from './constants'
import { drawBbox } from './drawBbox'

const binarAppsFullLogo = new Image(104, 32)
binarAppsFullLogo.src = 'logoBAfull.png'

type DrawBinarLogoBig = {
  ctx: CanvasRenderingContext2D
  height: number
  properWidth: number
  scaleX: number
  scaleY: number
  width: number
  x: number
  y: number
}

export const drawBinarLogoBig = ({
  ctx,
  scaleX,
  scaleY,
  width,
  x,
  y,
}: DrawBinarLogoBig) => {
  ctx.fillStyle = '#fff'

  drawBbox({
    ctx,
    filled: true,
    height: 32 * scaleY,
    radii: 19,
    rounded: true,
    width: 104 * scaleX,
    x: (x + width + 20) * scaleX + barOffsetX,
    y: (y + 12) * scaleY,
  })

  ctx.drawImage(
    binarAppsFullLogo,
    (x + width + 32) * scaleX + barOffsetX,
    (y + 20) * scaleY,
    72 * scaleX,
    16 * scaleY
  )
}
