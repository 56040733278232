type ClearCanvasProps = {
  ctx: CanvasRenderingContext2D | null
  canvasWidth: number
  canvasHeight: number
}

export const clearCanvas = ({
  ctx,
  canvasHeight,
  canvasWidth,
}: ClearCanvasProps) => {
  if (ctx === null) return
  ctx?.clearRect(0, 0, canvasWidth, canvasHeight)
  ctx.canvas.width = canvasWidth
  ctx.canvas.height = canvasHeight
}
