import * as faceapi from '@vladmandic/face-api'

export const getMostProbableExpression = ({
  expressions,
}: {
  expressions: Omit<faceapi.FaceExpressions, 'asSortedArray'>
}) => {
  const expression = Object.keys(expressions)
    .filter((el) => el !== 'asSortedArray')
    .reduce(function (a: string, b: string) {
      return expressions[
        a as keyof Omit<faceapi.FaceExpressions, 'asSortedArray'>
      ] > expressions[b as keyof Omit<faceapi.FaceExpressions, 'asSortedArray'>]
        ? a
        : b
    }) as keyof Omit<faceapi.FaceExpressions, 'asSortedArray'>

  return { expression, probability: expressions[expression] }
}
