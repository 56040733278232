export const barHeight = 8
export const barOffsetX = 25
export const barOffsetY = 110
export const barWidth = 220

export const forbiddenPointNames = [
  'nose',
  'left_ear',
  'right_ear',
  'left_eye',
  'right_eye',
] as const

export const pointScoreThreshold = 0.2
