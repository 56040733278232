import * as tf from '@tensorflow/tfjs-core'
// import { ObjectDetection, load } from '@tensorflow-models/coco-ssd'
import * as faceapi from '@vladmandic/face-api'
import * as poseDetection from '@tensorflow-models/pose-detection'
import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { Loader } from '../components'
import { TensorFlowContextProvider } from '../context'

export const TensorFlowProvider: FC<PropsWithChildren> = ({ children }) => {
  const [faceApiModelLoaded, setFaceApiModelLoaded] = useState<boolean | null>(
    null
  )
  const [poseDetector, setPoseDetector] =
    useState<poseDetection.PoseDetector | null>(null)

  const [isTfReady, setIsTfReady] = useState(false)

  const prepareTf = useCallback(async () => {
    try {
      if (!isTfReady) {
        await tf.ready()
        setIsTfReady(true)
      }
    } catch (e) {
      console.log('Failed to make tf ready', e)
    }
  }, [isTfReady])

  const loadFaceApiModel = useCallback(async () => {
    try {
      const MODEL_URL = '/models'
      await Promise.all([
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
      ])
      setFaceApiModelLoaded(true)
    } catch (e) {
      console.log('Failed to load face api model', e)
    }
  }, [])
  // }, [cocoSSDModel])

  const loadPoseDetector = useCallback(async () => {
    try {
      if (!poseDetector) {
        const moveNetPoseDetector = await poseDetection.createDetector(
          poseDetection.SupportedModels.MoveNet,
          {
            modelType: poseDetection.movenet.modelType.MULTIPOSE_LIGHTNING,
            enableTracking: true,
            trackerType: poseDetection.TrackerType.Keypoint,
          }
        )
        setPoseDetector(moveNetPoseDetector)
      }
    } catch (e) {
      console.log('Failed to load pose detector.', e)
    }
  }, [poseDetector])

  const loadModels = useCallback(async () => {
    try {
      await Promise.all([loadFaceApiModel(), loadPoseDetector()])
    } catch (e) {
      console.log('Loading models failed', e)
    }
  }, [loadFaceApiModel, loadPoseDetector])

  useEffect(() => {
    prepareTf()
  }, [prepareTf])

  useEffect(() => {
    if (isTfReady) {
      loadModels()
    }
  }, [isTfReady, loadModels])

  const value = useMemo(() => {
    return {
      isTfReady,
      poseDetector,
    }
  }, [isTfReady, poseDetector])

  if (!poseDetector && !faceApiModelLoaded) {
    return <Loader />
  }

  return (
    <TensorFlowContextProvider value={value}>
      {children}
    </TensorFlowContextProvider>
  )
}
