import * as poseDetection from '@tensorflow-models/pose-detection'
import { pointScoreThreshold } from './constants'

type DrawPointProps = {
  ctx: CanvasRenderingContext2D | null
  point: poseDetection.Keypoint
  scaleX: number
  scaleY: number
}

export const drawPoint = ({ ctx, point, scaleX, scaleY }: DrawPointProps) => {
  if (ctx === null) return

  // @ts-ignore
  if (point?.score < pointScoreThreshold) return
  ctx.fillStyle = 'yellow'
  ctx.beginPath()
  ctx.arc(scaleX * point.x, scaleY * point.y, 4, 0 * Math.PI, 2 * Math.PI)
  ctx.fill()
}
