const binarAppsLogo = new Image(24, 24)
binarAppsLogo.src = 'logoBA.png'

type DrawBinarLogoSmall = {
  ctx: CanvasRenderingContext2D
  properWidth: number
  scaleX: number
  scaleY: number
  x: number
  y: number
}

export const drawBinarLogoSmall = ({
  ctx,
  properWidth,
  scaleX,
  scaleY,
  x,
  y,
}: DrawBinarLogoSmall) => {
  ctx.save()

  // The image will only be visible inside the circular clipping path
  ctx.beginPath()
  ctx.arc(
    (x + properWidth / 2) * scaleX - 100,
    y * scaleY - 85 - 5,
    20,
    0,
    Math.PI * 2
  )
  ctx.closePath()
  ctx.clip()

  // draw the image into the clipping region
  ctx.drawImage(
    binarAppsLogo,
    (x + properWidth / 2) * scaleX - 125,
    y * scaleY - 85 - 20 - 5,
    40,
    40
  )

  // restore the context to its unaltered state
  ctx.restore()

  // save the context in its unaltered state
  ctx.save()
}
